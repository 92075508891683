import type { Media_Plain } from "@webamboos/admin";

interface OgMetaInput {
  title: string;
  description: string;
  image?: Media_Plain;
}

export const useSeoAttributes = () => {
  function defineOgMeta(meta: OgMetaInput | null) {
    const { title, description, image } = meta || {};
    return [
      { hid: "description", name: "description", content: description },

      // Open Graph
      { hid: "og:title", property: "og:title", content: title || "" },
      {
        hid: "og:description",
        property: "og:description",
        content: description || "",
      },
      image
        ? { hid: "og:image", property: "og:image", content: image.url }
        : [],

      // Twitter Card
      { hid: "twitter:title", name: "twitter:title", content: title || "" },
      {
        hid: "twitter:description",
        name: "twitter:description",
        content: description || "",
      },
      image
        ? {
            hid: "twitter:image",
            property: "twitter:image",
            content: image.url,
          }
        : [],
    ].flat();
  }
  return { defineOgMeta };
};
